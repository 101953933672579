import { html, LitElement, css, svg } from 'lit';
import { customElement, property, query, state } from 'lit/decorators';
import '../../components/slide-show';
import { SlideShow } from '../../components/slide-show';

export interface Slide {
  title: string;
  description: string;
  company: string;
  product: string;
  imagePath: string;
}

/**
 * The slideshow for the login/registration/etc screens.
 */
@customElement('login-slides')
export class LoginSlides extends LitElement {
  @property({ type: Array }) slides: Slide[] = [];

  @state() disableSlides = false;

  @query('slide-show#images') imagesSlideShow!: SlideShow;
  @query('slide-show#cards') cardsSlideShow!: SlideShow;

  render() {
    return html`
      <slide-show id="images">
        ${this.slides.map((slide) => html`
          <img src="${slide.imagePath}" />
        `)}
      </slide-show>
      <slide-show id="cards">
        ${this.slides.map((slide, i) => html`
          <!-- A container is necessary for the slide-show element to apply styles to. -->
          <div class="feature-slide">
            <div class="feature-card">
              <div style="color: rgba(255, 255, 255, 0.6); margin-bottom: 2px;">Use Change to</div>
              <div style="font-family: 'Manrope'; font-size: 2em; line-height: 1.1; font-weight: 400; margin-bottom: 10px;">${slide.title}</div>
              <div style="color: rgba(255, 255, 255, 0.8); margin-bottom: 24px;">${slide.description}</div>
              <div style="color: rgba(255, 255, 255, 0.6);">How ${slide.company} did it</div>
              <div style="font-family: 'Manrope'; font-size: 1.5em; font-weight: 300;">${slide.product}</div>
              </div>
            </div>
          </div>
        `)}
      </slide-show>
      <div class="arrows">
        ${arrow(() => this.previous())}
        ${arrow(() => this.next())}
      </div>
    `;
  }

  next() {
    if (this.disableSlides) return;
    this.disableSlides = true;
    this.imagesSlideShow.next();
    this.cardsSlideShow.next();
    setTimeout(() => this.disableSlides = false, 800);
  }

  previous() {
    if (this.disableSlides) return;
    this.disableSlides = true;
    this.imagesSlideShow.previous();
    this.cardsSlideShow.previous();
    setTimeout(() => this.disableSlides = false, 800);
  }

  static styles = css`
    :host {
      display: block;
    }
    slide-show {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    slide-show#images {
      --overhang: 50px;
    }
    slide-show#cards {
      --overhang: 150px;
    }

    .feature-slide.position-left .feature-card, .feature-slide.position-right .feature-card {
      /* Can't animate the opacity of the card without causing a Chrome but with the blur filter.
       * As a workaround, animate the background-color and color.
       */
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.1);
    }
    .feature-card {
      position: absolute;
      bottom: calc(var(--padding) + var(--overhang));
      left: calc(var(--padding) + var(--overhang));
      width: calc(100% - 2 * var(--padding) - 2 * var(--overhang));
      color: white;
      padding: 24px;
      box-sizing: border-box;
      z-index: 100;
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1.000);

      background-color: rgba(0, 0, 0, 0.29);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(11.8px);
      -webkit-backdrop-filter: blur(11.8px);
    }

    .arrows {
      user-select: none;
      position: absolute;
      bottom: calc(var(--padding) + 12px);
      right: calc(var(--padding) + 24px);
      z-index: 200;
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
    .arrow {
      cursor: pointer;
    }
    .arrow:hover .circle {
      fill-opacity: 0.25;
      transition: fill-opacity 0.2s ease-in-out;
    }
    .arrow:nth-of-type(2) {
      transform: rotate(180deg);
    }
  `;
}

function arrow(click: () => void) {
  return svg`
    <svg @click=${() => click()} class="arrow" width="60" height="60" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="circle" d="M35 8.75C20.5025 8.75 8.75 20.5025 8.75 35C8.75 49.4975 20.5025 61.25 35 61.25C49.4975 61.25 61.25 49.4975 61.25 35C61.25 20.5025 49.4975 8.75 35 8.75Z" fill="white" fill-opacity="0" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M33.332 25.7305L24.0625 35L33.332 44.2695" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M45.9375 35H24.0625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;
}