import { html, css, LitElement, nothing } from 'lit';
import { customElement, property, state, query } from 'lit/decorators';
import SlDialog from '@shoelace-style/shoelace/dist/components/dialog/dialog';
import { Dialog } from 'components/dialog';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/dialog/dialog';
import '@shoelace-style/shoelace/dist/components/divider/divider';

@customElement('help-dialog')
export class HelpDialog extends LitElement {
  @property({ type: String }) userType: 'merchant' | 'nonprofit';
  @query('sl-dialog') dialog: SlDialog;

  open() {
    this.dialog.open = true;
  }

  render() {
    return html`
      <sl-dialog label="Help" .open=${false}>
        <p>Need help? Please check out our 
          <a
            href=${this.userType == 'merchant' ? 'https://www.getchange.io/faq/business' : 'https://www.getchange.io/faq/nonprofit'}
            target="_blank"
          >FAQ</a>
          or email us at
          <a href="mailto:hello@getchange.io">hello@getchange.io</a>.
        </p>
      </sl-dialog>
    `;
  }

  static styles = [
    Dialog.styles,
    css`
      sl-dialog::part(title) {
        font-size: 24px;
        padding-bottom: 4px;
      }
      sl-dialog::part(body) {
        padding-top: 0;
      }
    `
  ];
}
