import { html, LitElement, css, CSSResultGroup, nothing, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';
import '../../components/login-slides';
import { Slide } from '../../components/login-slides';

/**
 * A layout for login and registration screens.
 */
export abstract class LoginLayout extends LitElement {
  @property({ type: Array }) slides: Slide[] = [];
  @property() logoUrl!: string;
  @property() invertedLogoUrl!: string;

  render() {
    return html`
      <picture>
        <source media="(min-width: 901px)" srcset=${this.logoUrl} type="image/svg+xml">
        <source media="(max-width: 900px)" srcset=${this.invertedLogoUrl} type="image/svg+xml">
        <img src="${this.logoUrl}" alt="logo" class="logo" />
      </picture>
      <div id="left">
        ${this.renderLeftPanel()}
      </div>
      <div id="right">
        ${this.renderContent()}
      </div>
    `;
  };

  renderLeftPanel() {
    return html`
      <login-slides .slides=${this.slides}></login-slides>
    `;
  };

  protected abstract renderContent(): unknown;

  static styles: CSSResultGroup = [
  css`
    :host {
      display: grid;
      grid-template-columns: 1fr 1fr;
      --padding: 30px;
      width: 100%;
      height: 100vh;
    }
    @media only screen and (min-width: 1200px) {
      :host {
        --padding: 80px;
      }
    }
    @media only screen and (min-width: 1560px) {
      :host {
        --padding: 120px;
      }
    }
    #left {
      position: relative;
      overflow: hidden;
      padding: var(--padding);
      background-color: #1E1F21;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #left-panel .logo {
      position: relative;
      left: 0;
      top: 0;
    }
    #right {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    picture {
      position: absolute;
      width: 150px;
      left: var(--padding);
      top: var(--padding);
      z-index: 100;
    }
    .logo {
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, .5));
      width: 150px;
    }
    login-slides {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .form-input {
      max-width: 400px;
      width: 100%;
    }

    #oauth-form{
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      margin-bottom: 17px;
    }

    #form-divider {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

    }
    #gray-line {
      width: 40%;
      background-color: #d1d5db;
      height: 1px;
    }

    a, a:visited {
      font-weight: 500;
    }

    h2 {
      color: black;
      font-size: 25px;
    }

    .field {
      margin: 14px 0;
    }
    .field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #D3D3D3;
    }
    .field::-moz-placeholder { /* Firefox 19+ */
      color: #D3D3D3;
    }

    .shared-links {
      line-height: 30px;
      text-align: left;
    }

    sl-button[type=submit] {
      width: 100%;
    }

    sl-button[variant=text]::part(base), a {
      color: var(--sl-color-primary-600)
    }
    sl-button[variant=text]:hover::part(base), a:hover {
      color: var(--sl-color-primary-500)
    }
    sl-button[variant=text]:active::part(base), a:active {
      color: var(--sl-color-primary-400);
    }

    sl-input::part(form-control-label)::after {
      display: none;
    }

    @media only screen and (max-width: 900px) {
      :host {
        grid-template-columns: 1fr;
      }
      #left {
        display: none;
      }
      .logo {
        filter: none;
      }
    }
  `];
}

