import {html, LitElement, css} from 'lit';
import {customElement, property, state} from 'lit/decorators';

/**
 * A slideshow for the login/registration/etc screens.
 * NOTE: This element is hard-coded for three items. Generalization needed.
 */
@customElement('slide-show')
export class SlideShow extends LitElement {
  @property({ attribute: false }) slides: HTMLElement[] = [];
  @property({ type: Number }) index = 0;
  @state() direction: -1|1 = 1;

  render() {
    return html`
      <slot></slot>
    `;
  }

  updated() {
    [...this.children].forEach((slide, i) => {
      // Remove all classes that start with position or direction
      slide.classList.remove(...[...slide.classList].filter(c => c.startsWith('position') || c.startsWith('direction')));
      slide.classList.add(this.slidePositionClass(i));
      slide.classList.add(this.slideDirectionClass(i));
    });
  }

  slidePosition(originalPosition: number) {
    return [0, 1, -1][(originalPosition - this.index + 3) % 3];
  }

  slidePositionClass(originalPosition: number) {
    return `position-${['left', 'center', 'right'][this.slidePosition(originalPosition) + 1]}`;
  }

  slideDirectionClass(originalPosition: number) {
    const position = this.slidePosition(originalPosition);
    const direction = this.direction === 1 ? ['leaving', 'entering', 'crossing'][position + 1] : ['crossing', 'entering', 'leaving'][position + 1];
    return `direction-${direction}`;
  }

  public next() {
    this.direction = 1;
    this.index = (this.index + 1) % 3;
  }

  public previous() {
    this.direction = -1;
    this.index = (this.index - 1 + 3) % 3;
  }

  static styles = css`
    :host {
      display: block;
      --overhang: 0px;
    }

    slot::slotted(*) {
      position: absolute;
      top: calc(-1 * var(--overhang));
      left: calc(-1 * var(--overhang));
      width: calc(100% + 2 * var(--overhang));
      height: calc(100% + 2 * var(--overhang));
      object-fit: cover;
      transition: transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
    slot::slotted(.position-left) {
      transform: translate3d(-100%, 0, 0);
    }
    slot::slotted(.position-center) {
      transform: translate3d(0, 0, 0);
    }
    slot::slotted(.position-right) {
      transform: translate3d(100%, 0, 0);
    }
    slot::slotted(.direction-entering) {
      z-index: 3;
    }
    slot::slotted(.direction-leaving) {
      z-index: 2;
    }
    slot::slotted(.direction-crossing) {
      z-index: 1;
      visibility: hidden;
    }
  `;
}
